<template>
  <div class="g17-tender-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleExport">导出Excel</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'

export default {
  name: 'G17TenderStat',
  components: {
    MySearchBar,
    MySpread
  },
  data () {
    return {
      spreadInfo: {
        sheetName: '工程材料进度年计划汇总表',
        columns: [
          {
            label: '序号',
            dataKey: 'materialListCode',
            width: '50'
          },
          {
            label: '材料类别',
            dataKey: 'parentMaterialListName',
            width: '120',
            autoMerge: true
          },
          {
            label: '材料名称',
            dataKey: 'materialListName',
            width: 200
          },
          {
            label: '单位',
            dataKey: 'unit',
            width: 90
          },
          {
            label: '合同总用量',
            dataKey: 'num',
            width: 120
          },
          {
            label: '本年度计划用量',
            dataKey: 'currentYearPlanNum',
            width: 120
          },
          {
            label: '本年度累计完成',
            children: [
              {
                label: '数量',
                dataKey: 'currentYearStatNum',
                width: 120
              },
              {
                label: '占年计划（%）',
                dataKey: 'currentYearStatPercent',
                width: 120
              }
            ]
          },
          {
            label: '自开工起工程累计完成',
            children: [
              {
                label: '数量',
                dataKey: 'totalStatNum',
                width: 120
              },
              {
                label: '占合同（%）',
                dataKey: 'totalStatPercent',
                width: 120
              }
            ]
          }
        ]
      },
      defaultDataList: [],
      engineeringList: [],
      tenderStatList: [],
      engineeringMap: {},
      otherTenderStats: [],
      reportDate: ''
    }
  },
  watch: {
    reportDate (newVal) {
      this.getData()
    }
  },
  methods: {
    handleExport () {
      this.$refs.mySpread.exportExcel('标段工程材料进度年计划汇总表.xlsx')
    },
    generateMapData () {
      this.defaultDataList = this.tenderStatList.map((item, index) => {
        item.index = index
        return item
      })

      this.$loading().close()
    },
    getData () {
      this.$myLoading()
      this.tenderStatList = []
      this.defaultDataList = []
      if (!this.reportDate) {
        this.$loading().close()
        return
      }
      const year = parseInt(this.reportDate.substr(0, 4))
      axios.get(utility.getRestFullUrl('projectYearStat', 'g17'), {
        params: {
          projectGuid: auth.getUserInfo().projectGuid,
          year
        }
      })
        .then(res => {
          if (res.data.code === 1) {
            this.tenderStatList = res.data.data

            this.generateMapData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.g17-tender-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
